.custom-signin-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.custom-signin-section-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 93%;
}
/* -------------------------------------- */
.custom-signin-form-section-arabic {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.custom-signin-form-section-arabic h1 {
  text-align: right;
}
/* -------------------------------------- */
.custom-signin-form-header p {
  font-size: 20px;
  line-height: 12px;
  font-weight: 500;
}
/* -------------------------------------- */
.custom-signin-single-input-wrapper {
  display: flex;
  flex-direction: column;
}
.custom-signin-single-input-wrapper-arabic {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/* -------------------------------------- */
.custom-signin-single-input-wrapper input {
  width: 320px;
  height: 50px;
  border-radius: 5px;
  border-style: solid;
  border-color: rgb(212, 212, 212);
  border-width: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2%;
}
.custom-signin-single-input-wrapper-arabic input {
  width: 320px;
  height: 50px;
  border-radius: 5px;
  border-style: solid;
  border-color: rgb(212, 212, 212);
  border-width: 1px;
  display: flex;
  flex-direction: column;
  padding-right: 2%;
}
/*-----------------------------------------------*/
#fullName {
  text-align: left;
  background-image: url("../../assets/utilities/user.jpg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 270px;
}
#fullNameArabic {
  text-align: right;
  background-image: url("../../assets/utilities/user.jpg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 25px;
}
/*-----------------------------------------------*/
#email {
  text-align: left;
  background-image: url("../../assets/utilities/email.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 270px;
}
#emailArabic {
  text-align: right;
  background-image: url("../../assets/utilities/email.png");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 22px;
}
/*-----------------------------------------------*/
#password {
  text-align: left;
  background-image: url("../../assets/utilities/lock.jpg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 270px;
}
#passwordArabic {
  text-align: right;
  background-image: url("../../assets/utilities/lock.jpg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 22px;
}
/* -------------------------------------- */
.custom-signin-single-input-wrapper input::placeholder {
  font-size: 16px;
  color: rgb(219, 219, 219);
}
.custom-signin-single-input-wrapper-arabic input::placeholder {
  font-size: 16px;
  color: rgb(219, 219, 219);
}
/* -------------------------------------- */
.custom-signin-single-input-wrapper input:focus {
  border-color: blue;
}
/* -------------------------------------- */
.custom-signin-button-wrapper button {
  width: 320px;
  height: 55px;
}
/* -------------------------------------- */
.custom-signin-have-an-account {
  text-align: center;
  width: 320px;
}
.custom-signin-have-an-account-arabic {
  text-align: center;
  width: 320px;
}
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1220px) {
  .custom-signin-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  /* -------------------------------------- */
  .custom-signin-section-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  /* -------------------------------------- */
  .custom-signin-form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
  }
  .custom-signin-form-section-arabic {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3%;
  }
  .custom-signin-form-section-arabic h1 {
    text-align: center;
  }
  /* -------------------------------------- */
  .custom-signin-right-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* -------------------------------------- */
  .custom-signin-form-header {
    text-align: center;
  }
}
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 620px) {
  /* -------------------------------------- */
  .custom-signin-right-image img {
    width: 90%;
  }
}
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 400px) {
  /* -------------------------------------- */
  .custom-signin-form-header {
    text-align: center;
  }
  /* -------------------------------------- */
  .custom-signin-form-header p {
    font-size: 16px;
    line-height: 12px;
    font-weight: 500;
  }
  /* -------------------------------------- */
  .custom-signin-single-input-wrapper input {
    width: 300px;
  }
  .custom-signin-single-input-wrapper-arabic input {
    width: 300px;
  }
  /* -------------------------------------- */
  .custom-signin-button-wrapper button {
    width: 300px;
    height: 55px;
  }
  /* -------------------------------------- */
  .custom-signin-have-an-account span {
    font-size: 14px;
  }
  .custom-signin-have-an-account-arabic span {
    font-size: 14px;
  }
}
