.compound-info-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.compound-info-section-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 93%;
}
/* -------------------------------------- */
.compound-info-left {
  width: 45%;
}
/* -------------------------------------- */
.compound-info-left img {
  width: 100%;
}
/* -------------------------------------- */
.floors-slider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* -------------------------------------- */
.compound-info-right {
  width: 40%;
}
/* -------------------------------------- */
.compound-info-right-first-section,
.compound-info-right-second-section,
.compound-info-right-third-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.compound-info-right-first-section-arabic,
.compound-info-right-second-section-arabic,
.compound-info-right-third-section-arabic {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  text-align: right;
}
.compound-info-right-fourth-section-arabic {
  text-align: right;
}
/* -------------------------------------- */
.compound-info-right-second-section p,
.compound-info-right-third-section p,
.compound-info-right-fourth-section p {
  font-size: 150%;
}
.compound-info-right-second-section-arabic p,
.compound-info-right-third-section-arabic p,
.compound-info-right-fourth-section-arabic p {
  font-size: 150%;
}
/* -------------------------------------- */
.compound-info-search-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* -------------------------------------- */
/* -------------------------------------- */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 1060px) {
  /* -------------------------------------- */
  .compound-info-section-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
  }
  /* -------------------------------------- */
  .compound-info-left {
    width: 100%;
  }
  /* -------------------------------------- */
  .compound-info-left img {
    width: 100%;
  }
  /* -------------------------------------- */
  .compound-info-right {
    width: 100%;
    margin-top: 5%;
  }
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 500px) {
  .compound-info-right-first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .compound-info-right-first-section-arabic {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  /* -------------------------------------- */
  .compound-info-right-second-section p,
  .compound-info-right-third-section p,
  .compound-info-right-fourth-section p {
    font-size: 100%;
  }
  .compound-info-right-second-section-arabic p,
  .compound-info-right-third-section-arabic p,
  .compound-info-right-fourth-section-arabic p {
    font-size: 100%;
  }
  /* -------------------------------------- */
  .compound-info-search-filter div {
    width: 90%;
  }
}
