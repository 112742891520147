.custom-contact-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
/*------------------------------------*/
.custom-contact-inner {
  width: 93%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.custom-contact-inner-arabic {
  width: 93%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
/*------------------------------------*/
.contact-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.contact-flex-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}
/*------------------------------------*/
.custom-contact-left-wrapper {
  width: 40%;
  margin-right: 10%;
}
.custom-contact-left-wrapper-arabic {
  width: 40%;
  margin-left: 10%;
  text-align: right;
}
/*------------------------------------*/
.custom-contact-right-wrapper {
  width: 40%;
  box-shadow: 2px 2px 30px rgb(219, 217, 217);
  padding: 1%;
}
.custom-contact-right-wrapper-arabic {
  width: 40%;
  box-shadow: 2px 2px 30px rgb(219, 217, 217);
  padding: 1%;
  text-align: right;
}
/*------------------------------------*/
.custom-contact-right-wrapper div {
  width: 100%;
}
.custom-contact-right-wrapper-arabic div {
  width: 100%;
}
/*------------------------------------*/
.input-fields {
  display: block;
  width: 100%;
  border-style: none;
  border-bottom: 1px solid rgb(168, 168, 168);
  padding-bottom: 2px;
}
/*------------------------------------*/
.input-fields-arabic {
  display: block;
  width: 100%;
  border-style: none;
  border-bottom: 1px solid rgb(168, 168, 168);
  padding-bottom: 2px;
  text-align: right;
}
.input-fields:focus {
  outline: none;
}
/*------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 825px) {
  .custom-contact-inner {
    width: 84%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-contact-inner-arabic {
    width: 84%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /*------------------------------------*/
  .custom-contact-left-wrapper {
    margin-right: 0%;
    width: 100%;
  }
  .custom-contact-left-wrapper-arabic {
    margin-right: 0%;
    width: 100%;
  }
  /*------------------------------------*/
  .custom-contact-right-wrapper {
    width: 100%;
    padding: 5%;
    box-shadow: 2px 2px 10px rgb(236, 230, 230);
  }
  .custom-contact-right-wrapper-arabic {
    width: 100%;
    padding: 5%;
    box-shadow: 2px 2px 10px rgb(236, 230, 230);
  }
}
