.preferences-header-wrapper-arabic {
  text-align: right;
}
/*-----------------------------------------*/
.preferences-input-field-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.preferences-input-field-wrapper-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  text-align: right;
}
/*-----------------------------------------*/
.preferences-field-label {
  width: 20%;
  font-size: 18px;
}
/*-----------------------------------------*/
.preferences-input-field-wrapper input,
.preferences-input-field-wrapper select {
  width: 50%;
  height: 50px;
  border-style: solid;
  border-color: rgb(212, 212, 212);
  border-width: 1px;
  color: gray;
  padding-left: 10px;
}
.preferences-input-field-wrapper-arabic input,
.preferences-input-field-wrapper-arabic select {
  width: 50%;
  height: 50px;
  border-style: solid;
  border-color: rgb(212, 212, 212);
  border-width: 1px;
  color: gray;
  padding-right: 10px;
  direction: rtl;
}
/*-----------------------------------------*/
.preferences-input-field-wrapper div {
  width: 25%;
}
.preferences-input-field-wrapper-arabic div {
  width: 25%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
/*-----------------------------------------*/
.preferences-header-wrapper p {
  font-size: 20px;
}
.preferences-header-wrapper-arabic p {
  font-size: 20px;
}
/*-----------------------------------------*/
.preferences-save-button {
  font-size: 12px !important;
  border-radius: 0px !important;
  height: 35px;
  width: 60px;
  border-radius: 2px !important;
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1200px) {
  /*-----------------------------------------*/
  .preferences-field-label {
    font-size: 14px;
  }
  /*-----------------------------------------*/
  .preferences-input-field-wrapper input,
  .preferences-input-field-wrapper select {
    padding-left: 6px;
    font-size: 14px;
  }
  .preferences-input-field-wrapper-arabic input,
  .preferences-input-field-wrapper-arabic select {
    padding-left: 6px;
    font-size: 14px;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1050px) {
  /*-----------------------------------------*/
  .preferences-field-label {
    font-size: 12px;
  }
  /*-----------------------------------------*/
  .preferences-input-field-wrapper input,
  .preferences-input-field-wrapper select {
    padding-left: 5px;
    font-size: 12px;
  }
  .preferences-input-field-wrapper-arabic input,
  .preferences-input-field-wrapper-arabic select {
    padding-left: 5px;
    font-size: 12px;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 900px) {
  /*-----------------------------------------*/
  .preferences-field-label {
    font-size: 10px;
    width: 15%;
  }
  /*-----------------------------------------*/
  .preferences-input-field-wrapper input,
  .preferences-input-field-wrapper select {
    font-size: 10px;
    width: 55%;
  }
  .preferences-input-field-wrapper-arabic input,
  .preferences-input-field-wrapper-arabic select {
    font-size: 10px;
    width: 55%;
  }
  /*-----------------------------------------*/
  .preferences-input-field-wrapper div {
    width: 25%;
  }
  .preferences-input-field-wrapper-arabic div {
    width: 25%;
  }
  /*-----------------------------------------*/
  .preferences-save-button {
    font-size: 10px !important;
    border-radius: 0px !important;
    height: 25px;
    width: 40px;
    border-radius: 2px !important;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .preferences-header-wrapper {
    align-items: center;
    text-align: center;
  }
  .preferences-header-wrapper-arabic {
    align-items: center;
    text-align: center;
  }
  /*-----------------------------------------*/
  .preferences-input-field-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .preferences-input-field-wrapper-arabic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /*-----------------------------------------*/
  .preferences-field-label {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }
  /*-----------------------------------------*/
  .preferences-input-field-wrapper input,
  .preferences-input-field-wrapper select {
    width: 100%;
    height: 30px;
    border-style: solid;
    border-color: rgb(212, 212, 212);
    border-width: 1px;
    color: gray;
    padding-left: 10px;
    font-size: 18px;
    text-align: center;
  }
  .preferences-input-field-wrapper-arabic input,
  .preferences-input-field-wrapper-arabic select {
    width: 100%;
    height: 30px;
    border-style: solid;
    border-color: rgb(212, 212, 212);
    border-width: 1px;
    color: gray;
    padding-left: 10px;
    font-size: 18px;
    text-align: center;
  }
  /*-----------------------------------------*/
  .preferences-input-field-wrapper div {
    margin-top: 5px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
  .preferences-input-field-wrapper-arabic div {
    margin-top: 5px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 475px) {
  .preferences-input-field-wrapper input,
  .preferences-input-field-wrapper select {
    font-size: 15px;
  }
  .preferences-input-field-wrapper-arabic input,
  .preferences-input-field-wrapper-arabic select {
    font-size: 15px;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 400px) {
  .preferences-input-field-wrapper input,
  .preferences-input-field-wrapper select {
    font-size: 12px;
  }
  .preferences-input-field-wrapper-arabic input,
  .preferences-input-field-wrapper-arabic select {
    font-size: 12px;
  }
}
