.custom-search-page-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.custom-search-page-image-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 98%;
}
.custom-search-page-image-slide {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.custom-search-page-image-inner img {
  width: 100%;
}
/* -------------------------------------- */
.custom-search-page-image-text-wrapper {
  position: absolute;
  left: 8%;
  top: 35%;
}
.custom-search-page-image-text-wrapper-arabic {
  position: absolute;
  right: 8%;
  top: 35%;
  text-align: right;
}
.custom-search-page-image-header-text {
  font-size: 40px;
  margin-bottom: 0px;
}
.custom-search-page-image-body-text {
  width: 40%;
  font-size: 16px;
  margin-bottom: 0px;
}
/* -------------------------------------- */
/* -------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */

@media screen and (max-width: 1023px) {
  .custom-search-page-image-text-wrapper {
    position: absolute;
    left: 8%;
    top: 15%;
  }
  .custom-search-page-image-text-wrapper-arabic {
    position: absolute;
    right: 8%;
    top: 15%;
    text-align: right;
  }
  .custom-search-page-image-header-text {
    font-size: 35px;
    margin-bottom: 0px;
  }
  .custom-search-page-image-body-text {
    width: 60%;
    font-size: 15px;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  .custom-search-page-image-text-wrapper {
    position: absolute;
    left: 8%;
    top: 15%;
  }
    .custom-search-page-image-text-wrapper-arabic {
    position: absolute;
    right: 8%;
    top: 15%;
  }
  .custom-search-page-image-header-text {
    font-size: 30px;
    margin-bottom: 0px;
  }
  .custom-search-page-image-body-text {
    width: 65%;
    font-size: 14px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 630px) {
  .custom-search-page-image-text-wrapper {
    position: absolute;
    left: 8%;
    top: 0%;
  }
    .custom-search-page-image-text-wrapper-arabic {
    position: absolute;
    right: 8%;
    top: 0%;
  }
  .custom-search-page-image-header-text {
    font-size: 25px;
    margin-bottom: 0px;
  }
  .custom-search-page-image-body-text {
    width: 60%;
    font-size: 10px;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 480px) {
  .custom-search-page-image-text-wrapper {
    position: absolute;
    left: 8%;
    top: 0%;
  }
    .custom-search-page-image-text-wrapper-arabic {
    position: absolute;
    right: 8%;
    top: 0%;
  }
  .custom-search-page-image-header-text {
    font-size: 15px;
    margin-bottom: 0px;
  }
  .custom-search-page-image-body-text {
    width: 70%;
    font-size: 8px;
    margin-bottom: 0px;
  }
}
