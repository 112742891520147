.custom-tabs-and-list-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* ------------------------------------------------------------------------------------------------ */
.custom-favourite-booking-tabs-wrapper {
  width: 93% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
}
.custom-tab-normal {
  border-bottom-style: solid;
  border-color: rgb(199, 197, 197);
  border-width: 2px;
  padding-bottom: 3%;
  font-size: 30px;
  font-weight: 600;
}
.custom-tab-active {
  color: rgb(58, 58, 255);
  border-bottom: solid;
  border-width: 2px;
  padding-bottom: 3%;
  font-size: 30px;
  font-weight: 600;
}
.tabs-middle-title span {
  font-size: 45px;
  font-weight: 700;
}
/* ------------------------------------------------------------------------------------------------ */
.custom-list-wrapper {
  width: 93%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 1300px) {
  .custom-tab-normal {
    font-size: 25px;
    font-weight: 500;
  }
  .custom-tab-active {
    font-size: 25px;
    font-weight: 600;
  }
  .tabs-middle-title span {
    font-size: 40px;
    font-weight: 700;
  }
}
@media screen and (max-width: 1100px) {
  .custom-tab-normal {
    font-size: 20px;
    font-weight: 500;
  }
  .custom-tab-active {
    font-size: 20px;
    font-weight: 600;
  }
  .tabs-middle-title span {
    font-size: 35px;
    font-weight: 700;
  }
}
@media screen and (max-width: 900px) {
  .custom-tab-normal {
    font-size: 15px;
    font-weight: 500;
  }
  .custom-tab-active {
    font-size: 15px;
    font-weight: 600;
  }
  .tabs-middle-title span {
    font-size: 30px;
    font-weight: 700;
  }
}
@media screen and (max-width: 768px) {
  .custom-tab-normal {
    font-size: 15px;
    font-weight: 500;
  }
  .custom-tab-active {
    font-size: 15px;
    font-weight: 600;
  }
  .tabs-middle-title span {
    font-size: 20px;
    font-weight: 700;
  }
}
@media screen and (max-width: 620px) {
  .custom-favourite-booking-tabs-wrapper {
    flex-direction: column-reverse !important;
  }
  .custom-favourite-booking-tabs-wrapper div{
    margin-bottom: 2%;
    text-align: center;
  }
  .tabs-middle-title{
    order: 1 !important;
  }
}
