.clickable {
  border-style: outset;
  border-color: rgb(231, 231, 231);
  border-radius: 5px;
  border-width: 2px;
  padding: 5%;
}
#custom-form-select {
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
  color: rgb(109, 97, 97);
  height: 50px;
  width: 220px;
  border-style: none;
  text-align: center !important;
  text-align-last: center !important;
  padding: 0px !important;
}
#custom-form-select-arabic {
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
  color: rgb(109, 97, 97);
  height: 50px;
  width: 220px;
  border-style: none;
  direction: rtl;
  text-align: center !important;
  text-align-last: center !important;
  padding: 0px !important;
  background-position: 5px;
}
.show-more-button-arabic {
  direction: rtl;
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  .show-more-button,
  .show-more-button-arabic {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    width: 120px !important;
    height: 30px;
    font-size: 10px !important;
  }
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 499px) {
  #custom-form-select {
    width: 100% !important;
  }
  #custom-form-select-arabic {
    width: 100% !important;
  }
}
