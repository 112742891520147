/*---------------------------------------*/
.property-details-right {
  width: 50%;
}
/*---------------------------------------*/
.view-details-modal-label {
  font-size: 24px;
}
/*---------------------------------------*/
.first-section,
.property-details-right-single-attribute {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.first-section-arabic,
.property-details-right-single-attribute-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  text-align: right;
}
/*---------------------------------------*/
.property-details-right-single-attribute i {
  width: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.property-details-right-single-attribute-arabic i {
  width: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
/*---------------------------------------*/
.view-details-search-icon {
  text-decoration: none !important;
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 950px) {
  /*---------------------------------------*/
  .property-details-right {
    width: 100%;
    margin-top: 5%;
  }
  /*---------------------------------------*/
  .view-details-modal-label {
    font-size: 18px;
  }
  /*---------------------------------------*/
  .first-section {
    text-align: center;
    flex-direction: column;
  }
  .first-section-arabic {
    text-align: center;
    flex-direction: column;
  }
}
