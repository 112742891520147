.location-about-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.location-about-section-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 93%;
}
.location-about-section-inner-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  width: 93%;
}
/* -------------------------------------- */
.location-about-section-inner div {
  width: 40%;
}
.location-about-section-inner-arabic div {
  width: 40%;
  direction: rtl;
}
/* -------------------------------------- */
.location-about-section-inner p {
  font-size: 107%;
}
.location-about-section-inner-arabic p {
  font-size: 107%;
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 768px) {
  /* -------------------------------------- */
  .location-about-section-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 93%;
  }
  .location-about-section-inner-arabic {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 93%;
  }
  /* -------------------------------------- */
  .location-about-section-inner div {
    width: 100%;
    text-align: center;
  }
  .location-about-section-inner-arabic div {
    width: 100%;
    text-align: center;
  }
}
