.custom-available-malls-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*----------------------------------*/
.custom-available-malls {
  width: 93%;
}
.custom-available-malls-arabic {
  width: 93%;
}
/*----------------------------------*/
.custom-available-malls-arabic h1 {
  text-align: right;
}
/*----------------------------------*/
.custom-available-malls-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.custom-available-malls-container-arabic {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  flex-wrap: wrap;
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 1024px) {
  .custom-available-malls h1,
  .custom-available-malls-arabic h1 {
    text-align: center;
  }
}
