.security-header-wrapper-arabic {
  text-align: right;
}
/*-----------------------------------------*/
.security-header-wrapper p {
  font-size: 20px;
}
.security-header-wrapper-arabic p {
  font-size: 20px;
}
/*-----------------------------------------*/
.security-input-field-wrapper {
  display: flex;
  flex-direction: row;
}
.security-input-field-wrapper-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  text-align: right;
}
/*-----------------------------------------*/
.security-field-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 12.5%;
}
.security-field-label-arabic {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 12.5%;
  justify-content: flex-end;
}
/*-----------------------------------------*/
.security-text-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}
.security-text-wrapper-arabic {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
  justify-content: flex-end;
}
/*-----------------------------------------*/
.security-buttons-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
}
.security-buttons-wrapper-arabic {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 30%;
  justify-content: flex-start;
}
/*-----------------------------------------*/
.send-email-button {
  font-size: 11px !important;
  border-radius: 0px !important;
  height: 40px;
  display: flex !important;
  flex-direction: row;
  align-items: center;
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1024px) {
  .security-field-label {
    width: 18%;
  }
  .security-field-label-arabic {
    width: 18%;
  }
  /*-----------------------------------------*/
  .security-field-label span {
    font-size: smaller;
  }
  .security-field-label-arabic span {
    font-size: smaller;
  }
  /*-----------------------------------------*/
  .security-text-wrapper {
    font-size: smaller;
  }
  .security-text-wrapper-arabic {
    font-size: smaller;
  }
  /*-----------------------------------------*/
  .security-buttons-wrapper {
    width: 30%;
    display: flex;
    flex-direction: column;
    font-size: smaller;
  }
  .security-buttons-wrapper-arabic {
    width: 30%;
    display: flex;
    flex-direction: column;
    font-size: smaller;
  }
  /*-----------------------------------------*/
  .send-email-button {
    font-size: 9px !important;
    border-radius: 0px !important;
    height: 22px;
    display: flex !important;
    flex-direction: row;
    align-items: center;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .security-header-wrapper {
    align-items: center;
    text-align: center;
  }
  .security-header-wrapper-arabic {
    align-items: center;
    text-align: center;
  }
  /*-----------------------------------------*/
  .security-input-field-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .security-input-field-wrapper-arabic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /*-----------------------------------------*/
  .security-field-label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  .security-field-label-arabic {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  /*-----------------------------------------*/
  .security-text-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  .security-text-wrapper-arabic {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  /*-----------------------------------------*/
  .security-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .security-buttons-wrapper-arabic {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
