.mall-floor-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.mall-floor-section-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 93%;
}
/* -------------------------------------- */
.mall-floor-left {
  width: 45%;
}
/* -------------------------------------- */
.mall-floor-left img {
  width: 100%;
}
/* -------------------------------------- */
.floors-slider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/* -------------------------------------- */
.mall-floor-right {
  width: 40%;
}
/* -------------------------------------- */
.mall-floor-right-first-section,
.mall-floor-right-second-section,
.mall-floor-right-third-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.mall-floor-right-first-section-arabic,
.mall-floor-right-second-section-arabic,
.mall-floor-right-third-section-arabic {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  text-align: right;
}
.mall-floor-right-fourth-section-arabic {
  text-align: right;
}
/* -------------------------------------- */
.mall-floor-right-second-section p,
.mall-floor-right-third-section p,
.mall-floor-right-fourth-section p {
  font-size: 150%;
}
.mall-floor-right-second-section-arabic p,
.mall-floor-right-third-section-arabic p,
.mall-floor-right-fourth-section-arabic p {
  font-size: 150%;
}
/* -------------------------------------- */
.mall-floor-search-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 1060px) {
  /* -------------------------------------- */
  .mall-floor-section-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
  }
  /* -------------------------------------- */
  .mall-floor-left {
    width: 100%;
  }
  /* -------------------------------------- */
  .mall-floor-left img {
    width: 100%;
  }
  /* -------------------------------------- */
  .mall-floor-right {
    width: 100%;
    margin-top: 5%;
  }
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 500px) {
  .mall-floor-right-first-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .mall-floor-right-first-section-arabic {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  /* -------------------------------------- */
  .mall-floor-right-second-section p,
  .mall-floor-right-third-section p,
  .mall-floor-right-fourth-section p {
    font-size: 100%;
  }
  .mall-floor-right-second-section-arabic p,
  .mall-floor-right-third-section-arabic p,
  .mall-floor-right-fourth-section-arabic p {
    font-size: 100%;
  }
  /* -------------------------------------- */
  .mall-floor-search-filter div {
    width: 90% !important;
  }
}
