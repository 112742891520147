.location-details-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.location-details-section-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 93%;
}
.location-details-section-inner-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 93%;
  text-align: right;
}
/* -------------------------------------- */
.location-details-section-text {
  width: 50%;
}
/* -------------------------------------- */
.location-details-section-text p {
  font-size: 24px;
}
/* -------------------------------------- */
.location-details-section-image {
  border-radius: 15px;
  width: 40%;
}
/* -------------------------------------- */
.location-details-section-image iframe {
  border-radius: 15px;
  width: 100%;
  height: 400px;
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  /* -------------------------------------- */
  .location-details-section-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .location-details-section-inner-arabic {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* -------------------------------------- */
  .location-details-section-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  /* -------------------------------------- */
  .location-details-section-text p {
    text-align: center;
  }
  /* -------------------------------------- */
  .location-details-section-image {
    width: 90%;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 500px) {
  /* -------------------------------------- */
  .location-details-section-text p {
    font-size: 16px;
  }
}
