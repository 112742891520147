.delete-user-modal-dialog {
  min-width: 300px !important;
  /* width: 700px !important; */
  max-width: 800px !important;
}
.delete-user-modal-header {
  border-bottom: none !important;
}
.delete-user-modal-body p {
  font-size: 18px;
}
.delete-user-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.delete-user-button-wrapper button {
  margin: 5px;
  height: 50px;
  width: 250px;
}
