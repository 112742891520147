.custom-available-companies-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.custom-available-companies-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95% !important;
}
.available-companies-carousel-slide {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.available-companies-carousel-slide-arabic {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.available-companies-carousel-slide-arabic h1 {
  direction: rtl;
}
.four-companies-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.four-companies {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.custom-company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 220px;
  height: 300px;
  padding: 2%;
}
.custom-first-company {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 220px;
  height: 300px;
  padding: 2%;
  box-shadow: 1px 1px 15px rgb(233, 225, 225);
}
.custom-first-company img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
.custom-company img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 1024px) {
  .available-companies-carousel-slide h1,
  .available-companies-carousel-slide-arabic h1 {
    text-align: center;
  }
}
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 975px) {
  .custom-company {
    width: 200px !important;
  }
  .custom-first-company {
    width: 200px !important;
  }
  .custom-first-company img {
    width: 80px !important;
    height: 80px !important;
  }
  .custom-company img {
    width: 80px !important;
    height: 80px !important;
  }
  .view-profile-button {
    font-size: 12px !important;
  }
}
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 900px) {
  .custom-company {
    width: 180px !important;
    height: 220px !important;
  }
  .custom-first-company {
    width: 180px !important;
    height: 220px !important;
  }
  .custom-first-company img {
    width: 70px !important;
    height: 70px !important;
  }
  .custom-company img {
    width: 70px !important;
    height: 70px !important;
  }
  .view-profile-button {
    font-size: 10px !important;
  }
} /* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 810px) {
  .custom-company {
    width: 160px !important;
  }
  .custom-first-company {
    width: 160px !important;
  }
  .custom-first-company img {
    width: 60px !important;
    height: 60px !important;
  }
  .custom-company img {
    width: 60px !important;
    height: 60px !important;
  }
  .view-profile-button {
    font-size: 10px !important;
  }
}
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 725px) {
  .custom-company {
    width: 140px !important;
  }
  .custom-first-company {
    width: 140px !important;
  }
  .custom-first-company img {
    width: 50px !important;
    height: 50px !important;
  }
  .custom-company img {
    width: 50px !important;
    height: 50px !important;
  }
  .view-profile-button {
    font-size: 10px !important;
  }
}
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 640px) {
  .custom-company {
    width: 120px !important;
  }
  .custom-first-company {
    width: 120px !important;
  }
  .custom-first-company img {
    width: 50px !important;
    height: 50px !important;
  }
  .custom-company img {
    width: 50px !important;
    height: 50px !important;
  }
}
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 560px) {
  .custom-company {
    width: 100px !important;
    height: 190px !important;
  }
  .custom-first-company {
    width: 100px !important;
    height: 190px !important;
  }
  .custom-first-company img {
    width: 50px !important;
    height: 50px !important;
  }
  .custom-company img {
    width: 50px !important;
    height: 50px !important;
  }
  .view-profile-button {
    font-size: 8px !important;
  }
}
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 480px) {
  .custom-company {
    width: 80px !important;
    height: 150px !important;
    font-size: 8px !important;
  }
  .custom-first-company {
    width: 80px !important;
    height: 150px !important;
    font-size: 8px !important;
  }
  .custom-company span {
    font-size: 8px !important;
  }
  .custom-first-company span {
    font-size: 8px !important;
  }
  .custom-first-company img {
    width: 50px !important;
    height: 50px !important;
  }
  .custom-company img {
    width: 50px !important;
    height: 50px !important;
  }
  .view-profile-button {
    font-size: 6px !important;
    padding: 4px !important;
  }
}
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 390px) {
  .custom-company {
    width: 60px !important;
    height: 100px !important;
    font-size: 6px !important;
  }
  .custom-first-company {
    width: 60px !important;
    height: 100px !important;
    font-size: 6px !important;
  }
  .custom-company span {
    font-size: 6px !important;
  }
  .custom-first-company span {
    font-size: 6px !important;
  }
  .custom-first-company img {
    width: 30px !important;
    height: 30px !important;
  }
  .custom-company img {
    width: 30px !important;
    height: 30px !important;
  }
  .view-profile-button {
    padding: 1px 2px 1px 2px !important;
  }
}
