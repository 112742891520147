.custom-navbar {
  display: flex;
  flex-direction: column;
}
/*-----------------------------*/
.custom-container-fluid-1 {
  border-bottom: solid;
  border-color: rgb(211, 211, 211);
  border-width: 1px;
  padding-bottom: 10px;
}
/*-----------------------------*/
.custom-container-fluid-2 {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
}
/*-----------------------------*/
.custom-container-fluid-2-arabic {
  width: 95%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
}
/*-----------------------------*/
.top-navbar-wrapper {
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
/*-----------------------------*/
.custom-navbar-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}
/*-----------------------------*/
.custom-navbar-nav-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: flex-start;
}
/*-----------------------------*/
.custom-navbar-nav-arabic input {
  text-align: right;
}
/*-----------------------------*/
.custom-navbar-nav span {
  font-size: 18px;
}

.custom-navbar-nav-arabic span {
  font-size: 18px;
}
/*-----------------------------*/
.address-section {
  border-right: solid;
  border-left: solid;
  border-color: rgb(211, 211, 211);
  border-width: 2px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 0.8rem;
}
/*-----------------------------*/
.phones-section,
.clock-section {
  font-size: 0.8rem;
}
/*-----------------------------*/
.custom-nav-item {
  color: rgb(35, 35, 49);
  text-decoration: none !important;
}
.custom-nav-item a {
  color: rgb(35, 35, 49);
  text-decoration: none;
}
/*-----------------------------*/
.custom-nav-item-active {
  color: rgb(0, 0, 255);
}
.custom-nav-item-active a {
  color: rgb(0, 0, 255);
  text-decoration: none;
}
/*-----------------------------*/
.navbar-dot {
  margin-top: -3px;
  font-size: 9px;
}
/*-----------------------------*/
.navbar-profile-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/*-----------------------------*/
.navbar-profile-section-arabic {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/*-----------------------------*/
.navbar-profile-section span {
  font-size: 18px;
}
.navbar-profile-section-arabic span {
  font-size: 18px;
}
/*-----------------------------*/
.profile-dropdown-items {
  text-decoration: none !important;
  color: black !important;
  width: 100% !important;
  text-align: left !important;
}
/*-----------------------------*/
.profile-dropdown-items:hover {
  color: rgb(18, 94, 194) !important;
  background-color: #a9b4b8 !important;
}
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 980px) {
  .phones-section,
  .address-section,
  .clock-section {
    padding-left: 10px;
    padding-right: 10px;
  }
}
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 900px) {
  /*-----------------------------*/
  .custom-container-fluid-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  /*-----------------------------*/
  .custom-container-fluid-2-arabic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .phones-section,
  .address-section,
  .clock-section {
    padding-left: 5px;
    padding-right: 5px;
    text-align: center;
  }
}
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 860px) {
  .navbar-elements-wrapper {
    width: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .top-navbar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .address-section {
    border: none;
    text-align: center;
  }
}
