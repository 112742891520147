.mall-units-prices-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.mall-units-prices-section-inner {
  width: 93%;
}
.mall-units-prices-section-inner-arabic {
  width: 93%;
}
.mall-units-prices-section-inner-arabic h1 {
  text-align: right;
}
/* -------------------------------------- */
.mall-units-prices-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.mall-units-prices-wrapper-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  flex-wrap: wrap;
}
/* -------------------------------------- */
.mall-units-prices-wrapper div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mall-units-prices-wrapper-arabic div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* -------------------------------------- */
.mall-units-prices-wrapper p {
  font-size: 120%;
}
/* -------------------------------------- */
.mall-units-prices-wrapper-arabic p {
  font-size: 120%;
}
/* -------------------------------------- */
.underline-none {
  text-decoration: none;
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .mall-units-prices-section-inner h1 {
    text-align: center !important;
  }
  .mall-units-prices-section-inner-arabic h1 {
    text-align: center !important;
  }
  /* -------------------------------------- */
  .mall-units-prices-wrapper div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 40%;
  }
  .mall-units-prices-wrapper-arabic div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 40%;
  }
  /* -------------------------------------- */
  .mall-units-prices-wrapper p {
    font-size: 95%;
  }
  /* -------------------------------------- */
  .mall-units-prices-wrapper-arabic p {
    font-size: 95%;
  }
}
