.custom-malls-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.custom-malls {
  width: 93%;
}
.custom-malls-arabic {
  width: 93%;
}
.custom-malls-arabic h2 {
  text-align: right;
}
.custom-malls-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.custom-malls-container-arabic {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  flex-wrap: wrap;
}
/* ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------------------------- */
@media screen and (max-width: 476px) {
  .custom-malls h2,
  .custom-malls-arabic h2 {
    text-align: center;
  }
}
