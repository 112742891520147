.label-slider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 5px;
  padding: 10px;
  border-radius: 5px;
  border-style: outset;
  border-width: 2px;
  border-color: rgb(173, 190, 214);
  text-align: center;
}
/*---------------------------*/
.slider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100% !important;
}
/*---------------------------*/
.slider-wrapper-labels {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: rgb(163, 163, 163);
}
/*---------------------------*/
.slider {
  width: 100%;
  box-shadow: none;
  border-style: none;
  font-size: 14px;
}
/*---------------------------*/
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 800px) {
  .slider {
    font-size: 12px;
  }
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 730px) {
  .label-slider-wrapper {
    font-size: 14px;
  }
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 660px) {
  .label-slider-wrapper {
    font-size: 12px;
  }
  .slider {
    font-size: 11px;
  }
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
