.custom-available-locations-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
/* --------------------------------------- */
.custom-available-locations-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 93%;
}
.custom-available-locations-inner-arabic {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 93%;
}
.custom-available-locations-inner-arabic h1 {
  text-align: right;
}
/* --------------------------------------- */
.custom-available-locations {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.custom-available-locations-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.custom-single-location {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 1 0 50%;  */
}
.custom-single-location img {
  border-radius: 5px;
  width: 270px;
}
/* --------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 700px) {
  .custom-single-location img {
    border-radius: 10px;
    width: 250px;
  }
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 565px) {
  .custom-single-location img {
    border-radius: 10px;
    width: 200px;
  }
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 475px) {
  .custom-single-location img {
    border-radius: 10px;
    width: 150px;
  }
  .custom-single-location a {
    font-size: 12px;
  }
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 370px) {
  .custom-single-location img {
    border-radius: 10px;
    width: 130px;
  }
  .custom-single-location a {
    font-size: 10px;
  }
}
