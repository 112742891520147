.success-booking-modal-dialog {
  min-width: 300px !important;
  max-width: 600px !important;
}
/*---------------------------------------*/
.success-booking-modal-content {
  padding-right: 3%;
  padding-left: 3%;
  padding-bottom: 3%;
}
/*---------------------------------------*/
.success-booking-modal-header {
  border-bottom: none !important;
}
