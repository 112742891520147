.location-top-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.location-top-image-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 93%;
}
/* -------------------------------------- */
.location-top-image-slide {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
/* -------------------------------------- */
.location-top-image {
  width: 100%;
}
/* -------------------------------------- */
.location-top-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
/* -------------------------------------- */
.location-top-image-subcontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.location-top-image-subcontainer-arabic {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-end;
}
/* -------------------------------------- */
.location-logo {
  border-radius: 50%;
  margin: 0% 3% 0% 3%;
}
/* -------------------------------------- */
.location-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}
.location-data-arabic {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
}
/* -------------------------------------- */
.location-name {
  font-size: 180%;
}
/* -------------------------------------- */
.location-acres-properties-companies {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.location-acres-properties-companies-arabic {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/* -------------------------------------- */
.location-acres-properties-companies span,
.location-map span {
  font-size: 150%;
  font-weight: 300;
}
.location-acres-properties-companies-arabic span {
  font-size: 150%;
  font-weight: 300;
}
/* -------------------------------------- */
.location-map span {
  text-decoration: underline;
}
/* -------------------------------------- */
/* -------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 1040px) {
  .location-data {
    width: 50%;
  }
  .location-data-arabic {
    width: 50%;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 976px) {
  /* -------------------------------------- */
  .location-name {
    font-size: 150%;
  }
  /* -------------------------------------- */
  .location-acres-properties-companies span,
  .location-map span {
    font-size: 120%;
  }
  .location-acres-properties-companies-arabic span {
    font-size: 120%;
  }
  /* -------------------------------------- */
  .location-logo {
    width: 100px;
    height: 100px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 780px) {
  /* -------------------------------------- */
  .location-name {
    font-size: 120%;
  }
  /* -------------------------------------- */
  .location-acres-properties-companies span,
  .location-map span {
    font-size: 100%;
  }
  .location-acres-properties-companies-arabic span {
    font-size: 100%;
  }
  /* -------------------------------------- */
  .location-logo {
    width: 90px;
    height: 90px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 690px) {
  /* -------------------------------------- */
  .location-name {
    font-size: 100%;
  }
  /* -------------------------------------- */
  .location-acres-properties-companies span,
  .location-map span {
    font-size: 80%;
  }
  .location-acres-properties-companies-arabic span {
    font-size: 80%;
  }
  /* -------------------------------------- */
  .location-logo {
    width: 80px;
    height: 80px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 575px) {
  /* -------------------------------------- */
  .location-name {
    font-size: 80%;
  }
  /* -------------------------------------- */
  .location-acres-properties-companies span,
  .location-map span {
    font-size: 60%;
  }
  .location-acres-properties--arabic span {
    font-size: 60%;
  }
  /* -------------------------------------- */
  .location-logo {
    width: 70px;
    height: 70px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 480px) {
  /* -------------------------------------- */
  .location-name {
    font-size: 70%;
  }
  /* -------------------------------------- */
  .location-acres-properties-companies span,
  .location-map span {
    font-size: 50%;
  }
  .location-acres-properties-companies-arabic span {
    font-size: 50%;
  }
  /* -------------------------------------- */
  .location-logo {
    width: 60px;
    height: 60px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 425px) {
  /* -------------------------------------- */
  .location-name {
    font-size: 50%;
  }
  /* -------------------------------------- */
  .location-acres-properties-companies span,
  .location-map span {
    font-size: 40%;
  }
  .location-acres-properties-companies-arabic span {
    font-size: 40%;
  }
  /* -------------------------------------- */
  .location-logo {
    width: 50px;
    height: 50px;
  }
}
