.personal-details-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.personal-details-section-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 83%;
}
.personal-details-section-inner-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 83%;
}
/* -------------------------------------- */
.personal-details-left {
  width: 70%;
}
/* -------------------------------------- */
.personal-details-right {
  width: 30%;
}
/* -------------------------------------- */
.personal-details-right img {
  width: 80%;
}
/* -------------------------------------- */
.custom-tabs-wrapper {
  border-style: none;
  border-radius: 35px;
  box-shadow: 10px 10px 60px -2px rgb(233, 225, 225) !important;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 2%;
}
.custom-tabs-wrapper-arabic {
  border-style: none;
  border-radius: 35px;
  box-shadow: 10px 10px 60px -2px rgb(233, 225, 225) !important;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding: 2%;
}
/* -------------------------------------- */
.custom-tabs-wrapper .nav-link {
  border-style: none !important;
  color: black;
}
.custom-tabs-wrapper-arabic .nav-link {
  border-style: none !important;
  color: black;
}
/* -------------------------------------- */
.custom-tabs-wrapper .nav-item .active {
  border-radius: 35px !important;
  color: #077bff !important;
  background-color: rgba(139, 211, 230, 0.562) !important;
  font-weight: 500;
}
.custom-tabs-wrapper-arabic .nav-item .active {
  border-radius: 35px !important;
  color: #077bff !important;
  background-color: rgba(139, 211, 230, 0.562) !important;
  font-weight: 500;
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  /* -------------------------------------- */
  .personal-details-section-inner {
    flex-direction: column;
  }
  .personal-details-section-inner-arabic {
    flex-direction: column;
  }
  /* -------------------------------------- */
  .personal-details-left {
    width: 100%;
  }
  /* -------------------------------------- */
  .personal-details-right {
    width: 100%;
  }
  /* -------------------------------------- */
  .custom-tabs-wrapper {
    border-style: none;
    border-radius: 35px;
    box-shadow: 10px 10px 60px -2px rgb(233, 225, 225) !important;
    display: flex;
    flex-direction: row !important;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 2%;
    font-size: 12px;
  }
  .custom-tabs-wrapper-arabic {
    border-style: none;
    border-radius: 35px;
    box-shadow: 10px 10px 60px -2px rgb(233, 225, 225) !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    justify-content: space-evenly;
    align-items: center;
    width: 100% !important;
    padding: 2%;
    font-size: 12px;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 475px) {
  /* -------------------------------------- */
  .custom-tabs-wrapper .nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
  }
  .custom-tabs-wrapper-arabic .nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
  }
  /* -------------------------------------- */
  .custom-tabs-wrapper .nav-item .active {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
  }
  .custom-tabs-wrapper-arabic .nav-item .active {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px;
  }
}
