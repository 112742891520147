.custom-search-page-engine-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  direction: ltr;
}
/* -------------------------------------- */
/* -------------------------------------- */
.custom-sliders {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* -------------------------------------- */
/* -------------------------------------- */
.choices-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.choices-wrapper-arabic {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  direction: rtl;
}
.choices {
  border-style: solid;
  border-radius: 5px;
  margin: 2px;
  background-color: rgb(204, 200, 200);
  border-color: rgb(204, 200, 200);
  flex: 1 0 46%;
}
.choices-active {
  border-style: solid;
  border-radius: 5px;
  margin: 2px;
  background-color: #77bff7;
  border-color: #77bff7;
  flex: 1 0 46%;
}
/* -------------------------------------- */
/* -------------------------------------- */
.from-to-input-wrapper {
  display: flex;
  flex-direction: row;
}
.from-to-input-wrapper-arabic {
  display: flex;
  flex-direction: row;
  direction: rtl;
}
.label-and-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px;
}
.label-and-input-wrapper-arabic {
  display: flex;
  flex-direction: row;
  align-items: center;
  direction: rtl;
  margin: 5px;
}
#min-price,
#max-price,
#floor-number,
#rooms-number,
#min-installment,
#max-installment {
  background-color: rgb(238, 238, 238);
  border-radius: 5px;
  color: rgb(109, 97, 97);
  height: 40px;
  width: 80px;
  border-style: none;
  text-align: center !important;
  text-align-last: center !important;
  padding: 0px !important;
  margin: 5px;
}
#min-price::-webkit-outer-spin-button,
#min-price::-webkit-inner-spin-button,
#max-price::-webkit-outer-spin-button,
#max-price::-webkit-inner-spin-button,
#floor-number::-webkit-outer-spin-button,
#floor-number::-webkit-inner-spin-button,
#rooms-number::-webkit-outer-spin-button,
#rooms-number::-webkit-inner-spin-button,
#min-installment::-webkit-outer-spin-button,
#min-installment::-webkit-inner-spin-button,
#max-installment::-webkit-outer-spin-button,
#max-installment::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/* -------------------------------------- */
/* -------------------------------------- */
.custom-radio-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.radio-choice {
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.radio-choice input,
.radio-choice-arabic input {
  margin: 3px;
}
.radio-choice-arabic {
  width: 120px;
  display: flex;
  flex-direction: row;
  align-items: center;
  direction: rtl;
}
/* -------------------------------------- */
/* -------------------------------------- */
.search-button-width {
  width: 100% !important;
  height: 50px;
}
/* -------------------------------------- */
/* -------------------------------------- */
.filters-badge {
  width: 95%;
  padding: 10px;
  border-radius: 5px;
}
.filters-badge-arabic {
  width: 95%;
  direction: rtl;
  padding: 10px;
  border-radius: 5px;
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 730px) {
  .radio-choice {
    width: 110px;
  }
  .radio-choice-arabic {
    width: 110px;
  }
  #min-price,
  #max-price,
  #floor-number,
  #rooms-number,
  #min-installment,
  #max-installment {
    height: 30px;
    width: 60px;
  }
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 660px) {
  .radio-choice {
    width: 95px;
  }
  .radio-choice-arabic {
    width: 95px;
  }
}
