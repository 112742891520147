.personal-details-first-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.personal-details-first-section-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: right;
}
/*-----------------------------------------*/
.personal-details-profile-picture-wrapper img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
/*-----------------------------------------*/
.personal-details-profile-picture-wrapper img:hover {
}
/*-----------------------------------------*/
.personal-details-input-field-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.personal-details-input-field-wrapper-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  text-align: right;
}
/*-----------------------------------------*/
.personal-details-field-label {
  width: 15%;
  font-size: 18px;
}
/*-----------------------------------------*/
.personal-details-input-field-wrapper input,
.personal-details-input-field-wrapper select {
  width: 55%;
  height: 50px;
  border-style: solid;
  border-color: rgb(212, 212, 212);
  border-width: 1px;
  color: gray;
  padding-left: 10px;
}
.personal-details-input-field-wrapper-arabic input,
.personal-details-input-field-wrapper-arabic select {
  width: 55%;
  height: 50px;
  border-style: solid;
  border-color: rgb(212, 212, 212);
  border-width: 1px;
  color: gray;
  padding-right: 10px;
  direction: rtl;
}
/*-----------------------------------------*/
.personal-details-input-field-wrapper div {
  width: 25%;
}
.personal-details-input-field-wrapper-arabic div {
  width: 25%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
/*-----------------------------------------*/
.personal-details-header-wrapper p {
  font-size: 20px;
}
/*-----------------------------------------*/
.personal-details-save-button {
  font-size: 12px !important;
  border-radius: 0px !important;
  height: 35px;
  width: 60px;
  border-radius: 2px !important;
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 953px) {
  /*-----------------------------------------*/
  .personal-details-field-label {
    width: 10%;
    font-size: 14px;
  }
  /*-----------------------------------------*/
  .personal-details-input-field-wrapper input,
  .personal-details-input-field-wrapper select {
    width: 55%;
    padding-left: 6px;
    font-size: 14px;
  }
  .personal-details-input-field-wrapper-arabic input,
  .personal-details-input-field-wrapper-arabic select {
    width: 55%;
    padding-left: 6px;
    font-size: 14px;
  }
  /*-----------------------------------------*/
  .personal-details-input-field-wrapper div {
    width: 30%;
  }
  .personal-details-input-field-wrapper-arabic div {
    width: 30%;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .personal-details-first-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .personal-details-first-section-arabic {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  /*-----------------------------------------*/
  .personal-details-header-wrapper {
    align-items: center;
    text-align: center;
  }
  /*-----------------------------------------*/
  .personal-details-profile-picture-wrapper {
    margin-bottom: 2%;
  }
  /*-----------------------------------------*/
  .personal-details-input-field-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .personal-details-input-field-wrapper-arabic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /*-----------------------------------------*/
  .personal-details-field-label {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }
  /*-----------------------------------------*/
  .personal-details-input-field-wrapper input,
  .personal-details-input-field-wrapper select {
    width: 100%;
    height: 30px;
    border-style: solid;
    border-color: rgb(212, 212, 212);
    border-width: 1px;
    color: gray;
    padding-left: 10px;
    font-size: 18px;
    text-align: center;
  }
  .personal-details-input-field-wrapper-arabic input,
  .personal-details-input-field-wrapper-arabic select {
    width: 100%;
    height: 30px;
    border-style: solid;
    border-color: rgb(212, 212, 212);
    border-width: 1px;
    color: gray;
    padding-left: 10px;
    font-size: 18px;
    text-align: center;
  }
  /*-----------------------------------------*/
  .personal-details-input-field-wrapper div {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
  .personal-details-input-field-wrapper-arabic div {
    width: 100%;
    margin-top: 5px;
    text-align: center;
    justify-content: center;
  }
  /*-----------------------------------------*/
  .personal-details-save-button {
    font-size: 10px !important;
    border-radius: 0px !important;
    height: 25px;
    width: 45px;
    border-radius: 2px !important;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 475px) {
  .personal-details-input-field-wrapper input,
  .personal-details-input-field-wrapper select {
    font-size: 15px;
  }
  .personal-details-input-field-wrapper-arabic input,
  .personal-details-input-field-wrapper-arabic select {
    font-size: 15px;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 400px) {
  .personal-details-input-field-wrapper input,
  .personal-details-input-field-wrapper select {
    font-size: 12px;
  }
  .personal-details-input-field-wrapper-arabic input,
  .personal-details-input-field-wrapper-arabic select {
    font-size: 12px;
  }
}
