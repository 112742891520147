.custom-search-results-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*----------------------------------*/
.custom-search-results-badge {
  width: 95%;
}
.custom-search-results-badge-arabic {
  width: 95%;
  direction: rtl;
}
/*----------------------------------*/
.custom-pagination{
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
/*----------------------------------*/
.custom-search-results-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.custom-search-results-container-arabic {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  flex-wrap: wrap;
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 1024px) {
  .custom-search-results h1,
  .custom-search-results-arabic h1 {
    text-align: center;
  }
}
