.payment-system-calculator-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/*---------------------------------------*/
.payment-system-calculator-inner {
  display: flex;
  flex-direction: column;
  width: 95%;
}
.payment-system-calculator-inner-arabic {
  display: flex;
  flex-direction: column;
  width: 95%;
  direction: rtl;
}
/*---------------------------------------*/
.payment-system-calculator-inner p {
  font-size: 140%;
}
.payment-system-calculator-inner-arabic p {
  font-size: 140%;
}
/*---------------------------------------*/
.payment-system-calculator-table {
  text-align: center;
  border-style: solid !important;
  border-top-left-radius: 5px !important;
  border-width: 1px;
}
/*---------------------------------------*/
#payment-system-calculator-select{
border-color: rgb(206, 90, 90);
border-radius: 5px;
background-color: rgb(250, 216, 165);
padding:5px;
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  /*---------------------------------------*/
  .payment-system-calculator-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .payment-system-calculator-inner-arabic {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  /*---------------------------------------*/
  .payment-system-calculator-table {
    font-size: 14px;
  }
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 540px) {
  .payment-system-calculator-table {
    font-size: 12px;
  }
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 485px) {
  .payment-system-calculator-table {
    font-size: 11px;
  }
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 435px) {
  .payment-system-calculator-table {
    font-size: 10px;
  }
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 370px) {
  .payment-system-calculator-table {
    font-size: 9px;
  }
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 360px) {
  .payment-system-calculator-table {
    font-size: 8px;
  }
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 330px) {
  .payment-system-calculator-table {
    font-size: 7.5px;
  }
}