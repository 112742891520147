.custom-card-element {
  display: flex;
  flex-direction: row;
  margin-bottom: 2%;
  padding: 1%;
  box-shadow: 1px 1px 15px rgb(233, 225, 225);
}
/* ---------------------------------------- */
.custom-card-image {
  border-radius: 10px;
}
/* ---------------------------------------- */
.custom-card-image img {
  border-radius: 10px;
  width: 200px;
  height: 200px;
}
/* ---------------------------------------- */
.custom-card-detail {
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.custom-card-detail div {
  margin: 1% !important;
}
/* ---------------------------------------- */
.custom-card-detail-arabic {
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  direction: rtl;
}
.custom-card-detail-arabic div {
  margin: 1% !important;
}
/* ---------------------------------------- */
.custom-card-address {
  display: flex;
  flex-direction: row;
  align-items: center;
}
/* ---------------------------------------- */
.custom-badge-blue {
  background-color: rgb(183, 231, 231);
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: 2% 3% 2% 3%;
  border-radius: 5px;
  margin: 1%;
}
/* ---------------------------------------- */
.custom-badge-orange {
  background-color: rgb(255, 230, 183);
  width: fit-content;
  color: orange;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: 2% 3% 2% 3%;
  border-radius: 5px;
  margin: 1%;
}
/* ---------------------------------------- */
.custom-badge-green {
  background-color: rgb(193, 236, 193);
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  padding: 2% 3% 2% 3%;
  border-radius: 5px;
  margin: 1%;
}
/* ---------------------------------------- */
.custom-card-tags {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* ---------------------------------------- */
.custom-like-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
/* ---------------------------------------- */
.custom-card-floors {
  display: flex;
  flex-direction: column;
}
/* ---------------------------------------- */
.custom-card-name {
  font-size: 16px !important;
}
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 980px) {
  .custom-card-image img {
    width: 160px;
    height: 160px;
  }
  .custom-card-detail {
    width: 180px;
  }
  .custom-card-detail-arabic {
    width: 180px;
    direction: rtl;
  }
  .custom-card-address span,
  .custom-card-address i,
  .custom-card-floors span,
  .custom-card-floors i {
    font-size: 12px;
  }
  .custom-badge-blue,
  .custom-badge-green,
  .custom-badge-orange {
    font-size: 12px;
  }
  .custom-card-name {
    font-size: 13px !important;
  }
}
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 790px) {
  .custom-card-image img {
    width: 130px;
    height: 130px;
  }
  .custom-card-detail {
    width: 150px;
  }
  .custom-card-detail-arabic {
    width: 150px;
    direction: rtl;
  }
  .custom-card-address span,
  .custom-card-address i,
  .custom-card-floors span,
  .custom-card-floors i {
    font-size: 10px;
  }
  .custom-badge-blue,
  .custom-badge-green,
  .custom-badge-orange {
    font-size: 10px;
  }
  .custom-card-name {
    font-size: 12px !important;
  }
  .custom-card-button {
    font-size: 10px !important;
  }
}
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 660px) {
  .custom-card-image img {
    width: 100px;
    height: 100px;
  }
  .custom-card-detail {
    width: 110px;
  }
  .custom-card-detail-arabic {
    width: 110px;
  }
  .custom-card-address span,
  .custom-card-address i,
  .custom-card-floors span,
  .custom-card-floors i {
    font-size: 9px;
  }
  .custom-badge-blue,
  .custom-badge-green,
  .custom-badge-orange {
    font-size: 8px;
  }
  .custom-card-name {
    font-size: 9px !important;
  }
  .custom-card-button {
    font-size: 8px !important;
    padding: 0px 2px 1px 2px !important;
  }
}
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 500px) {
  .custom-card-image img {
    width: 75px;
    height: 75px;
  }
  .custom-card-detail {
    width: 85px;
  }
  .custom-card-detail-arabic {
    width: 85px;
  }
  .custom-card-address span,
  .custom-card-address i,
  .custom-card-floors span,
  .custom-card-floors i {
    font-size: 7px;
  }
  .custom-badge-blue,
  .custom-badge-green,
  .custom-badge-orange {
    font-size: 7px;
  }
  .custom-card-name {
    font-size: 7.5px !important;
  }
}
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
/*------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 400px) {
  .custom-card-image img {
    width: 60px;
    height: 60px;
  }
  .custom-card-detail {
    width: 70px;
  }
  .custom-card-detail-arabic {
    width: 70px;
  }
  .custom-card-address span,
  .custom-card-address i,
  .custom-card-floors span,
  .custom-card-floors i {
    font-size: 6px;
  }
  .custom-badge-blue,
  .custom-badge-green,
  .custom-badge-orange {
    font-size: 7px;
  }
  .custom-card-name {
    font-size: 7px !important;
  }
}
