.custom-latestprojects-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.custom-latestprojects-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 93%;
}
.latest-projects-carousel-slide {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.latest-projects-carousel-slide-arabic {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.latest-projects-carousel-slide-arabic h2 {
  text-align: right;
}
.custom-latest-single-project {
  width: 300px;
}
.custom-latest-single-project-arabic {
  width: 300px;
  text-align: right;
}
.custom-latest-single-project img,
.custom-latest-single-project-arabic img {
  width: 300px;
  border-radius: 5px;
}
.three-latest-projects {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
/*-------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .latest-projects-carousel-slide {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .latest-projects-carousel-slide-arabic {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-latest-single-project {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-latest-single-project-arabic {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-malls-address {
    text-align: center;
  }
}
