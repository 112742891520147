.custom-mob-app-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.custom-mob-app {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.custom-mob-app img{
  width: inherit;
}
