.custom-top-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.custom-top-image-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 93%;
}
.custom-top-image-slide {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.custom-top-image-inner img {
  width: 100%;
}
/* -------------------------------------- */
.custom-top-image-text-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.custom-top-image-header-text {
  font-size: 35px;
  margin-bottom: 0px;
}
.custom-top-image-body-text {
  font-size: 20px;
  margin-bottom: 0px;
}
.custom-top-image-button {
  width: 180px;
  height: 40px;
  font-size: 16px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
/* -------------------------------------- */
/* -------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */

@media screen and (max-width: 768px) {
  .custom-top-image-text-wrapper {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-top-image-header-text {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .custom-top-image-body-text {
    font-size: 10px;
    margin-bottom: 0px;
  }
  .custom-top-image-button {
    width: 120px;
    height: 35px;
    font-size: 12px !important;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */

@media screen and (max-width: 400px) {
  .custom-top-image-button {
    font-size: 8px !important;
    width: 80px;
    height: 25px;
    display: inline-flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}