.custom-footer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-shadow: 2px 2px 30px rgb(219, 217, 217);
  position: "absolute";
  bottom: 0;
  margin-top: auto;
}
.custom-footer-inner {
  width: 84%;
  display: flex;
  flex-direction: row;
}
.custom-footer-inner-arabic {
  width: 84%;
  display: flex;
  flex-direction: row-reverse;
}
.footer-social-icons {
  margin-left: 25%;
}
.footer-social-icons-arabic {
  margin-right: 35%;
}
@media screen and (max-width: 445px) {
  .footer-social-icons {
    margin-left: 0%;
  }
  .footer-social-icons-arabic {
    margin-right: 0%;
  }
  .custom-footer-inner {
    width: 84%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-footer-inner-arabic {
    width: 84%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
