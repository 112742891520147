.custom-residential-properties-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.custom-residential-properties {
  width: 93%;
}
.custom-residential-properties-arabic {
  width: 93%;
  text-align: right;
}
.custom-residential-properties-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.custom-residential-properties-container-arabic {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
}
.custom-residential-properties-element {
  flex: 1 0 33%; /* explanation below */
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-residential-properties-element-arabic {
  flex: 1 0 33%; /* explanation below */
  margin-bottom: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-residential-properties-image-button {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.custom-residential-properties-image-button img {
  width: 200px;
  height: 200px;
  border-radius: 5px;
}
.custom-residential-properties-image-button:hover {
  opacity: 0.8;
}
.custom-residential-properties-image-button:hover a {
  visibility: visible;
  opacity: 1;
}
.residential-absolute-more-button {
  visibility: hidden;
  position: absolute;
  top: 85px;
  left: 65px;
}
.text-darkblue {
  color: rgb(12, 51, 71);
}
.text-bold {
  font-weight: 700;
}
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */
@media screen and (max-width: 476px) {
  .custom-residential-properties {
    width: 93%;
    text-align: center;
  }
  .custom-residential-properties-arabic {
    width: 93%;
    text-align: center;
  }
  /* ---------------------------- */
  /* ---------------------------- */
  /* ---------------------------- */
  .custom-residential-properties-element {
    flex: 1 0 33%; /* explanation below */
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-residential-properties-element-arabic {
    flex: 1 0 33%; /* explanation below */
    margin-bottom: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* ---------------------------- */
  /* ---------------------------- */
  /* ---------------------------- */
  .custom-residential-properties-image-button img {
    width: 70px;
    height: 70px;
    border-radius: 5px;
  }
  .residential-absolute-more-button {
    visibility: hidden;
    position: absolute;
    top: 22px;
    left: 12px;
    width: 45px !important;
    height: 25px !important;
    font-size: 8px !important;
  }
}
