.view-details-modal-dialog {
  min-width: 300px !important;
  max-width: 1000px !important;
}
/*---------------------------------------*/
.view-details-modal-content {
  padding-right: 3%;
  padding-left: 3%;
  padding-bottom: 3%;
}
/*---------------------------------------*/
.view-details-modal-header {
  border-bottom: none !important;
}
.view-details-modal-header-arabic {
  border-bottom: none !important;
  width: 100%;
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
}
.btn-close-arabic {
  position: absolute !important;
  left: 30px;
}
/*---------------------------------------*/
.property-details-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
/*---------------------------------------*/
.property-details-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
}
/*---------------------------------------*/
.property-details-left img {
  width: 100%;
}

/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 950px) {
  /*---------------------------------------*/
  .property-details-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /*---------------------------------------*/
  .view-details-modal-header h3 {
    width: 100%;
    text-align: center;
  }
  .view-details-modal-header-arabic h3 {
    width: 100%;
    text-align: center;
  }
  /*---------------------------------------*/
  .property-details-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5%;
  }
  /*---------------------------------------*/
  .property-details-left img {
    width: 70%;
  }
  /*---------------------------------------*/
  .property-details-left-floor-slider {
    width: 100%;
    text-align: center;
  }
}
