.location-main-features-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.location-main-features-section-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 93%;
}
/* -------------------------------------- */
.location-main-features-left,
.location-main-features-right {
  width: 45%;
}
.location-main-features-right-arabic {
  width: 45%;
  text-align: right;
}
/* -------------------------------------- */
.location-main-features-right-intro {
  font-size: 140%;
}
/* -------------------------------------- */
.location-main-features-right-list-item {
}
.location-main-features-right-list-item-arabic {
  direction: rtl;
}
/* -------------------------------------- */
.location-main-features-right-list-item-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 140%;
}
/* -------------------------------------- */
.location-main-features-right-list-item-text i {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 1270px) {
  /* -------------------------------------- */
  .location-main-features-section-inner {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 93%;
  }
  /* -------------------------------------- */
  .location-main-features-left,
  .location-main-features-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .location-main-features-right-arabic {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* -------------------------------------- */
  .location-main-features-right-intro {
    text-align: center;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 575px) {
  /* -------------------------------------- */
  .location-main-features-left img {
    width: 100% !important ;
  }
  /* -------------------------------------- */
  .location-main-features-right-list-item-text {
    font-size: 90%;
  }
  /* -------------------------------------- */
  .location-main-features-right-list-item-text i {
    width: 30px;
    height: 30px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 390px) {
  .location-main-features-right h1 {
    text-align: center;
  }
  .location-main-features-right-arabic h1 {
    text-align: center;
  }
  /* -------------------------------------- */
  .location-main-features-right-list-item-text {
    font-size: 70%;
  }
  /* -------------------------------------- */
  .location-main-features-right-list-item-text i {
    width: 26px;
    height: 26px;
  }
}
