.custom-clients-reviews-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.custom-clients-reviews {
  width: 94%;
}
.custom-clients-reviews-arabic {
  width: 94%;
}
.custom-clients-reviews-arabic h2 {
  text-align: right;
}
.custom-clients-reviews-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.custom-clients-reviews-container-arabic {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  flex-wrap: wrap;
}
.custom-clients-reviews-container-arabic div {
text-align: right;
}
.custom-clients-reviews-element {
  width: 350px;
  margin: 5px;
  margin-bottom: 2%;
  padding: 2%;
}
.custom-clients-reviews-element img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.second-review {
  box-shadow: 2px 2px 30px rgb(219, 217, 217);
  z-index: 1;
}
.third-review {
  z-index: 2;
  background-color: white;
}
