.custom-carousel-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/*----------------------------------*/
.custom-carousel-inner {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 93%;
}
/*----------------------------------*/
.custom-carousel-inner-social {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  height: 320px;
  width: 50px;
}
/*----------------------------------*/
.custom-carousel-inner-social div {
  transform: rotate(-90deg);
  font-weight: 500;
}
/*----------------------------------*/
.carousel-slide {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*----------------------------------*/
.custom-carousel-inner img {
  width: 100%;
}
/*----------------------------------*/
.custom-carousel-text {
  position: absolute;
  left: 5%;
  top: 40%;
}
/*----------------------------------*/
.custom-carousel-text-arabic {
  position: absolute;
  right: 5%;
  top: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/*----------------------------------*/
.custom-carousel-text p {
  font-size: 30px;
}
/*----------------------------------*/
.custom-carousel-text-arabic p {
  font-size: 30px;
  text-align: right;
}
/*----------------------------------*/
.custom-carousel-button {
  width: 180px;
  height: 40px;
  font-size: 16px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
/*-------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 1000px) {
  .custom-carousel-text {
    position: absolute;
    left: 5%;
    top: 30%;
  }
  .custom-carousel-text-arabic {
    position: absolute;
    right: 5%;
    top: 30%;
  }
  .custom-carousel-text p {
    font-size: 25px;
  }
  .custom-carousel-text-arabic p {
    font-size: 25px;
  }
  .custom-carousel-inner-social {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    height: 220px;
    width: 50px;
  }
  .custom-carousel-inner-social div span {
    font-size: 14px;
  }
  .custom-carousel-button {
    width: 180px;
    height: 40px;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
}
/*-------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  .custom-carousel-inner {
    flex-direction: column;
  }
  .custom-carousel-inner-social {
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    height: fit-content;
    margin-bottom: 2%;
  }
  .custom-carousel-inner-social div {
    transform: none;
  }
  .custom-carousel-text {
    position: absolute;
    left: 5%;
    top: 25%;
  }
  .custom-carousel-text-arabic {
    position: absolute;
    right: 5%;
    top: 25%;
  }
  .custom-carousel-text p {
    font-size: 18px;
  }
  .custom-carousel-text-arabic p {
    font-size: 18px;
  }
}
/*-------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 575px) {
  .custom-carousel-button {
    font-size: 10px !important;
    width: 80px;
    height: 20px;
    display: inline-flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .custom-carousel-text {
    position: absolute;
    left: 5%;
    top: 20%;
  }
  .custom-carousel-text-arabic {
    position: absolute;
    right: 5%;
    top: 20%;
  }
  .custom-carousel-text p {
    font-size: 15px;
  }
  .custom-carousel-text-arabic p {
    font-size: 15px;
  }
}
/*-------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------*/
/*-------------------------------------------------------------------------------------------------------*/
@media screen and (max-width: 400px) {
  .custom-carousel-button {
    font-size: 8px !important;
    width: 70px;
    height: 20px;
    display: inline-flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .custom-carousel-text {
    position: absolute;
    left: 5%;
    top: 15%;
  }
  .custom-carousel-text-arabic {
    position: absolute;
    right: 5%;
    top: 15%;
  }
  .custom-carousel-text p {
    font-size: 12px;
    margin-bottom: 5px !important;
  }
  .custom-carousel-text-arabic p {
    font-size: 12px;
    margin-bottom: 5px !important;
  }
}
