.custom-company-profile-feedback-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.custom-company-profile-feedback-section-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 93%;
}
/* -------------------------------------- */
.custom-company-profile-feedback-section {
  width: 100%;
}
.custom-company-profile-feedback-section-arabic {
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/* -------------------------------------- */
.custom-company-profile-feedback-section textarea {
  width: 65%;
  min-height: 125px;
  border-radius: 10px;
  font-size: 24px;
  color: rgb(66, 66, 66);
  border-style: none;
  background-color: rgb(235, 235, 235);
}
.custom-company-profile-feedback-section-arabic textarea {
  width: 65%;
  min-height: 125px;
  border-radius: 10px;
  font-size: 24px;
  color: rgb(66, 66, 66);
  border-style: none;
  background-color: rgb(235, 235, 235);
  text-align: right;
}
/* -------------------------------------- */
.custom-company-profile-feedback-section textarea::placeholder {
  padding-left: 2%;
  padding-top: 2%;
  font-size: 24px;
}
.custom-company-profile-feedback-section-arabic textarea::placeholder {
  padding-left: 2%;
  padding-top: 2%;
  font-size: 24px;
}
/* -------------------------------------- */
.custom-company-profile-single-feedback-wrapper {
  display: flex;
  width: 65%;
  padding: 2%;
  border-radius: 5px;
  border-style: ridge;
}
.custom-company-profile-single-feedback-wrapper-arabic {
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
  width: 65%;
  color: rgb(66, 66, 66);
  padding: 2%;
  border-radius: 5px;
  border-right-style: outset;
  border-top-style: outset;
}
/* -------------------------------------- */
.custom-company-profile-single-feedback-replies {
  position: relative;
  display: flex;
  border-style: ridge;
  padding: 2%;
  border-radius: 5px;
  background-color: rgb(232, 232, 232);
}
.custom-company-profile-single-feedback-replies-arabic {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  text-align: right;
  border-style: ridge;
  padding: 2%;
  border-radius: 5px;
  background-color: rgb(231, 232, 233);
}
/* -------------------------------------- */
.custom-company-profile-single-feedback-wrapper img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 2%;
}
.custom-company-profile-single-feedback-wrapper-arabic img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 2%;
}
/* -------------------------------------- */
.year-like-comment-arabic {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}
.custom-company-profile-single-feedback-wrapper p,
span {
  font-size: 15px;
}
.custom-company-profile-single-feedback-wrapper-arabic p,
span {
  font-size: 15px;
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  /* -------------------------------------- */
  .custom-company-profile-feedback-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-company-profile-feedback-section-arabic {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* -------------------------------------- */
  .custom-company-profile-feedback-section textarea {
    width: 100%;
    min-height: 100px;
  }
  .custom-company-profile-feedback-section-arabic textarea {
    width: 100%;
    min-height: 100px;
  }
  /* -------------------------------------- */
  .custom-company-profile-single-feedback-wrapper {
    width: 100%;
  }
  .custom-company-profile-single-feedback-wrapper-arabic {
    width: 100%;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 575px) {
  /* -------------------------------------- */
  .custom-company-profile-feedback-section textarea::placeholder {
    font-size: 20px;
  }
  .custom-company-profile-feedback-section-arabic textarea::placeholder {
    font-size: 20px;
  }
  /* -------------------------------------- */
  .custom-company-profile-single-feedback-wrapper p,
  span {
    font-size: 13px;
  }
  .custom-company-profile-single-feedback-wrapper-arabic p,
  span {
    font-size: 13px;
  }
  /* -------------------------------------- */
  .custom-company-profile-single-feedback-wrapper img {
    width: 50px;
    height: 50px;
  }
  .custom-company-profile-single-feedback-wrapper-arabic img {
    width: 50px;
    height: 50px;
  }
}
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
/*----------------------------------------------------------------------------------------------*/
@media screen and (max-width: 400px) {
  /* -------------------------------------- */
  .custom-company-profile-feedback-section textarea::placeholder {
    font-size: 18px;
  }
  .custom-company-profile-feedback-section-arabic textarea::placeholder {
    font-size: 18px;
  }
  /* -------------------------------------- */
  .custom-company-profile-single-feedback-wrapper p,
  span {
    font-size: 10px;
  }
  .custom-company-profile-single-feedback-wrapper-arabic p,
  span {
    font-size: 10px;
  }
  /* -------------------------------------- */
  .custom-company-profile-single-feedback-wrapper img {
    width: 50px;
    height: 50px;
  }
  .custom-company-profile-single-feedback-wrapper-arabic img {
    width: 50px;
    height: 50px;
  }
}
