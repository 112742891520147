.company-profile-top-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.company-profile-top-image-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 93%;
}
/* -------------------------------------- */
.company-profile-top-image-slide {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
/* -------------------------------------- */
.company-profile-top-image {
  width: 100%;
}
/* -------------------------------------- */
.company-profile-top-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
/* -------------------------------------- */
.company-profile-top-image-subcontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}
.company-profile-top-image-subcontainer-arabic {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-end;
}
/* -------------------------------------- */
.company-profile-logo {
  border-radius: 50%;
  margin: 0% 3% 0% 3%;
}
/* -------------------------------------- */
.company-profile-data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  font-size: 20px;
}
.company-profile-data-arabic {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 70%;
  font-size: 20px;
}
/* -------------------------------------- */
.company-profile-name {
  font-size: 30px;
}
/* -------------------------------------- */
/* -------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 1260px) {
  .company-profile-data {
    width: 65%;
  }
  .company-profile-data-arabic {
    width: 65%;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 1040px) {
  .company-profile-data {
    width: 60%;
  }
  .company-profile-data-arabic {
    width: 60%;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 900px) {
  .company-profile-data {
    width: 55%;
  }
  .company-profile-data-arabic {
    width: 55%;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 800px) {
  /* -------------------------------------- */
  .company-profile-name {
    font-size: 20px;
  }
  .company-profile-logo {
    width: 120px;
    height: 120px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 690px) {
  .company-profile-top-image-subcontainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .company-profile-top-image-subcontainer-arabic {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .company-profile-logo {
    width: 90px;
    height: 90px;
  }
  .company-profile-data {
    font-size: 16px;
  }
  .company-profile-data-arabic {
    font-size: 16px;
  }
  .social-icons {
    font-size: 14px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 575px) {
  .company-profile-logo {
    width: 80px;
    height: 80px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 535px) {
  .company-profile-logo {
    width: 70px;
    height: 70px;
  }
  .company-profile-name {
    font-size: 16px;
  }
  .company-profile-data {
    font-size: 12px;
  }
  .company-profile-data-arabic {
    font-size: 12px;
  }
  .social-icons {
    font-size: 9px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 415px) {
  .company-profile-logo {
    width: 60px;
    height: 60px;
  }
  .company-profile-name {
    font-size: 12px;
  }
  .company-profile-data {
    font-size: 10px;
  }
  .company-profile-data-arabic {
    font-size: 10px;
  }
  .social-icons {
    font-size: 8px;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 370px) {
  .company-profile-logo {
    width: 50px;
    height: 50px;
  }
  .company-profile-name {
    font-size: 10px;
  }
  .company-profile-data {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 8px;
  }
  .company-profile-data-arabic {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 8px;
  }
  .social-icons {
    font-size: 6px;
  }
}
