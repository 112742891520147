.custom-company-projects-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
/*--------------------------------------*/
.custom-company-projects {
  width: 93%;
}
.custom-company-projects-arabic {
  width: 93%;
}
.custom-company-projects-arabic h1 {
  text-align: right;
}
/*--------------------------------------*/
.custom-company-projects-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------------ */
@media screen and (max-width: 768px) {
  .custom-company-projects {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-company-projects-arabic {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
