.mall-top-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* -------------------------------------- */
.mall-top-image-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95%;
}
/* -------------------------------------- */
.mall-top-image-slide {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
/* -------------------------------------- */
.mall-background-image {
  width: 100%;
}
/* -------------------------------------- */
.mall-top-image-container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
/* -------------------------------------- */
.mall-top-image-subcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* -------------------------------------- */
.mall-implemented-company {
  position: absolute;
  left: 2%;
  bottom: 8%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 30%;
}
.mall-implemented-company-arabic {
  position: absolute;
  right: 2%;
  bottom: 8%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  direction: rtl;
  width: 30%;
}
/* -------------------------------------- */
.mall-implemented-company img {
  border-radius: 50%;
  width: 50px;
}
.mall-implemented-company-arabic img {
  border-radius: 50%;
  width: 50px;
}
/* -------------------------------------- */
.mall-implemented-company div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.mall-implemented-company-arabic div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
/* -------------------------------------- */
.implemented-company-underline {
  font-size: 80%;
  font-weight: 700;
  text-decoration: underline;
}
/* -------------------------------------- */
.mall-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
/* -------------------------------------- */
.mall-name {
  font-size: 300%;
  font-weight: 400;
}
/* -------------------------------------- */
.mall-acres-floors {
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
  margin-top: 0%;
}
/* -------------------------------------- */
.mall-acres-floors span {
  font-size: 110%;
  font-weight: 400;
}
/* -------------------------------------- */
.mall-tags {
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
}
/* -------------------------------------- */
.mall-tags span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 100%;
  height: 30px;
  font-weight: 400;
}
/* -------------------------------------- */
.mall-explore-now a {
  width: 200px;
  height: 60px;
  font-size: 20px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
/* -------------------------------------- */
.mall-map span {
  position: absolute;
  right: 2%;
  bottom: 8%;
  font-size: 120%;
  font-weight: 500;
  text-decoration: underline;
}
.mall-map-arabic span {
  position: absolute;
  left: 2%;
  bottom: 8%;
  font-size: 120%;
  font-weight: 500;
  text-decoration: underline;
}
/* -------------------------------------- */
/* -------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 840px) {
  .mall-top-image-subcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  /* -------------------------------------- */
  .mall-data {
    order: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: fit-content;
    margin-bottom: 2%;
  }
  /* -------------------------------------- */
  .mall-name {
    font-size: 250%;
    font-weight: 400;
  }
  /* -------------------------------------- */
  .mall-acres-floors {
    margin-bottom: 1%;
  }
  /* -------------------------------------- */
  .mall-acres-floors span {
    font-size: 150%;
    font-weight: 400;
  }
  /* -------------------------------------- */
  .mall-tags {
    margin-bottom: 1%;
  }
  /* -------------------------------------- */
  .mall-tags span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 80%;
    height: 25px;
    font-weight: 400;
  }
  /* -------------------------------------- */
  .mall-implemented-company {
    width: 100%;
  }
  .mall-implemented-company-arabic {
    width: 100%;
  }
  /* -------------------------------------- */
  .mall-map {
    order: 3;
    width: 100%;
    margin: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .mall-map-arabic {
    order: 3;
    width: 100%;
    margin: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 30px;
  }
  /* -------------------------------------- */
  .mall-map span {
    font-size: 110%;
    position: relative;
  }
  .mall-map-arabic span {
    font-size: 110%;
    position: relative;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 775px) {
  /* -------------------------------------- */
  .mall-name {
    font-size: 200%;
    font-weight: 400;
  }
  /* -------------------------------------- */
  .mall-acres-floors span {
    font-size: 130%;
    font-weight: 300;
  }
  /* -------------------------------------- */
  .mall-tags span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 70%;
    height: 20px;
    font-weight: 300;
  }
  /* -------------------------------------- */
  .mall-explore-now a {
    width: 160px;
    height: 50px;
    font-size: 18px !important;
    display: inline-flex !important;
    flex: row;
    align-items: center;
    justify-content: center;
  }
  /* -------------------------------------- */
  .mall-map span {
    font-size: 100%;
    position: relative;
  }
  .mall-map-arabic span {
    font-size: 100%;
    position: relative;
  }
  .mall-implemented-company {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mall-implemented-company-arabic {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* -------------------------------------- */
  .mall-implemented-company img {
    border-radius: 50%;
    width: 40px;
    margin-right: 3%;
  }
  .mall-implemented-company-arabic img {
    border-radius: 50%;
    width: 40px;
    margin-right: 3%;
  }
  /* -------------------------------------- */
  .mall-implemented-company span {
    font-size: 80%;
  }
  .mall-implemented-company-arabic span {
    font-size: 80%;
    width: 100%;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 575px) {
  /* -------------------------------------- */
  .mall-name {
    font-size: 180%;
    font-weight: 400;
  }
  /* -------------------------------------- */
  .mall-acres-floors span {
    font-size: 100%;
    font-weight: 300;
  }
  /* -------------------------------------- */
  .mall-tags span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 65%;
    height: 18px;
    font-weight: 300;
  }
  /* -------------------------------------- */
  .mall-explore-now a {
    width: 150px;
    height: 35px;
    font-size: 12px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
  /* -------------------------------------- */
  .mall-map span {
    font-size: 80%;
    position: relative;
  }
  /* -------------------------------------- */
  .mall-implemented-company img {
    border-radius: 50%;
    width: 25px;
    margin-right: 3%;
  }
  .mall-implemented-company-arabic img {
    border-radius: 50%;
    width: 25px;
    margin-right: 3%;
  }
  /* -------------------------------------- */
  .mall-implemented-company span {
    font-size: 65%;
  }
  .mall-implemented-company-arabic span {
    font-size: 65%;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 480px) {
  /* -------------------------------------- */
  .mall-name {
    font-size: 150%;
    font-weight: 400;
    margin-bottom: 0%;
  }
  /* -------------------------------------- */
  .mall-acres-floors span {
    font-size: 75%;
    font-weight: 300;
  }
  /* -------------------------------------- */
  .mall-tags span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 55%;
    height: 15px;
    font-weight: 300;
  }
  /* -------------------------------------- */
  .mall-map span {
    font-size: 70%;
    font-weight: 600;
    position: relative;
  }
  .mall-map-arabic span {
    font-size: 70%;
    font-weight: 600;
    position: relative;
  }
  /* -------------------------------------- */
  .mall-implemented-company img {
    border-radius: 50%;
    width: 25px;
  }
  .mall-implemented-company-arabic img {
    border-radius: 50%;
    width: 25px;

  }
  /* -------------------------------------- */
  .mall-implemented-company span {
    font-size: 50%;
  }
  .mall-implemented-company-arabic span {
    font-size: 50%;
  }
}
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */
@media screen and (max-width: 400px) {
  /* -------------------------------------- */
  .mall-name {
    font-size: 120%;
    font-weight: 400;
    margin: 0px !important;
    padding: 0px !important;
  }
  /* -------------------------------------- */
  .mall-acres-floors {
    margin: 0px !important;
    padding: 0px !important;
  }
  .mall-acres-floors span {
    margin: 0px !important;
    padding: 0px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
  }
  /* -------------------------------------- */
  .mall-explore-now a {
    width: 120px;
    height: 30px;
    font-size: 12px !important;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
  }
  /* -------------------------------------- */
  .mall-tags span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 45%;
    height: 12px;
    font-weight: 300;
  }
  /* -------------------------------------- */
  .mall-map span {
    font-size: 48%;
    position: relative;
    font-weight: 900;
  }
  .mall-map-arabic span {
    font-size: 48%;
    position: relative;
    font-weight: 900;
  }
  /* -------------------------------------- */
  .mall-implemented-company img {
    border-radius: 50%;
    width: 22px;
  }
  .mall-implemented-company-arabic img {
    border-radius: 50%;
    width: 22px;
  }
  /* -------------------------------------- */
  .mall-implemented-company span {
    font-size: 35%;
  }
  .mall-implemented-company-arabic span {
    font-size: 35%;
  }
}
